import React from 'react';

class Persons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  render() {
      return (
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Person Title</h5>
            <h6 class="card-subtitle mb-2 text-muted">Person subtitle</h6>
            <p class="card-text">Person text goes here</p>
          </div>
        </div>
      );
  }

}

export default Persons;