import React from 'react';

class Artists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

componentDidMount() {
  fetch(window._env_.API_URL + '/artists', {
    headers: {
      'Authorization': window._env_.FRONTEND_TOKEN
    }
  }).then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
}

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>

          {items.map(item => (
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{item.Name}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{item.Description}</h6>
                <p class="card-text">more text goes here</p>
              </div>
            </div>
          ))}

        </div>
      );
    }
  }

}

export default Artists;