import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown
 } from "react-bootstrap";
import Artists from './Artists.js';
import Persons from './Persons.js';

function App() {
  return (

    <Router>
      <div>
         <Navbar bg="primary" variant="dark" expand="md">
           <Navbar.Brand href="/">Undeniable Talent</Navbar.Brand>
           <Nav.Link href="/login">Login</Nav.Link>
           <NavDropdown title="Options" id="basic-nav-dropdown">
             <NavDropdown.Item href="/persons">Persons</NavDropdown.Item>
           </NavDropdown>
         </Navbar>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/persons">Persons</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/persons">
            <Persons />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <Artists />;
}

function About() {
  return <div> add details here... </div>;
}

export default App;
